import { stringify } from 'query-string';
import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils, DataProvider } from 'react-admin';
import { User } from "@auth0/auth0-react";

type Params = {
  pagination: {
    page: number,
    perPage: number
  },
  sort: {
    field: string,
    order: string
  },
  filter: {
    author_id: number
  },
}

const apiUrl = 'https://suhg3nyar3.execute-api.ap-northeast-1.amazonaws.com/v1/';


const httpClient = (url:string, options:any = {}) => {
  options.mode = "cors"
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set("x-api-key",process.env.REACT_APP_API_KEY)
  return fetchUtils.fetchJson(url, options)
}


const dataProvider:DataProvider<string> = simpleRestProvider(
    apiUrl,
    httpClient
  );

const shopifyDataProvider = (prop:User) => {
  const nickname = prop.nickname;

  return {
  ...dataProvider,
    getList: (resource:string, params:Params) => {
      const { page, perPage } = params.pagination;
      const {field, order} = params.sort;
      const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page -1) * perPage, page * perPage -1]),
          filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json}) => {
        const getRange: string = headers.get("content-range")!;
        const getTotal: string = getRange.split("/").pop()!;
        return {
          data: json.map((resource: { product_id: number; }) => ({ ...resource, id: resource.product_id }) ),
          total: parseInt(getTotal, 10)
        }

      })
    },

    getOne: (resource: string, params:any) => {
      const url = `${apiUrl}/${resource}/${params.id}`
      return httpClient(url).then(({ json }) => {
        return {
           data: {...json, id: json.product_id}
        }
      })
    },

  
    create: (resource: string, params: any) => {
      const url = `${apiUrl}/${resource}`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({...params.data, created_by: nickname})
      }).then(({ headers, json}) => {
        return {
          data: { ...params.data, id: json.product_id },
        }
      })
    },
    
    update: (resource: string, params: any) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      
      return httpClient(url, {
        method: "PUT",
        body: JSON.stringify({...params.data, updated_by: nickname})
      }).then(({ json }) => {
        return {
          data: json,
        }
      })
    },

    delete: (resource: string, params: any) => {
      const query = {
        user: nickname
      };
      return httpClient(`${apiUrl}/${resource}/${params.id}?${stringify(query)}`, {
        method: "DELETE",
        headers: new Headers({
            "Content-Type": "text/plain",
        }),
      }).then(({ json }) => {
        return {
           data: {...json, id:json.product_id}
        }
      })
    },

    deleteMany: (resource: string, params: any) => {
      const query = {
        user: nickname
      };
      return Promise.all(
        params.ids.map((id: string) =>
          httpClient(`${apiUrl}/${resource}/${id}?${stringify(query)}`, {
            method: "DELETE",
            headers: new Headers({
              "Content-Type": "text/plain",
            }),
          })
        )
      ).then(responses => ({
        data: responses.map(({ json }) => json.id),
      }))
    },
  }
}

export default shopifyDataProvider