import { Edit, SimpleForm, TextInput, NumberInput, required } from 'react-admin';
import { Box, Typography} from '@mui/material';


export const PostEdit = () => (
    <Edit title= "Update record with id">

    <SimpleForm sx={{ maxWidth: 600 }}>
        <Typography variant="h6" gutterBottom>
            Products table:
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput source="product_id" label="Product ID" validate={required()}  /> 
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="title" /> 
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="body_html" options={{ multiline: true }} />
            </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image1" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image2" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image3" />   
            </Box>
        </Box>

            
        <Typography variant="h6" gutterBottom>
            Products_options table:
        </Typography>
        
        <NumberInput source="option_id" label="Option ID" validate={required()} />
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option1" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option2" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option3" />   
            </Box>
        </Box>


        <Typography variant="h6" gutterBottom>
            Products_variants table:
        </Typography>

        <NumberInput source="variant_id" label="Variant ID" validate={required()} />

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option_value1" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option_value2" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option_value3" />   
            </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="barcode" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image_src" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="stock" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="price" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="sku" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="weight" />   
            </Box>
        </Box>

    </SimpleForm>

    </Edit>
);

