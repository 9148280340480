import { Admin, Resource } from 'react-admin';
import { ProductsList } from './getProducts';
import { showDroducts } from "./showProducts";
import {PostCreate} from "./createProducts";
import {PostEdit} from "./editProducts";
import { useAuth0 } from "@auth0/auth0-react";

import shopifyDataProvider from "./shopifyDataProvider";
import CustomLayout from "./customLayout";


const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
  } = useAuth0();

  if (isLoading) {
    return <h2> Loading...</h2>;
  }

  if (error) {
    return <div> {error.message} </div>;
  }

  if (isAuthenticated){
    return (
      <Admin title="Example Admin" dataProvider={shopifyDataProvider(user!)} layout={CustomLayout}>
        <Resource name="products"
          list={ProductsList}
          show={showDroducts}
          edit={PostEdit} 
          create={PostCreate} />
      </Admin>
      
    )
  } else {
    loginWithRedirect();
    return <h2> Loading...</h2>;
  }
};

export default App;
