import { Show, SimpleShowLayout, TextField } from 'react-admin';


export const showDroducts = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField label="Product id" source="product_id" />
            <TextField label="Option id" source="option_id" />
            <TextField label= "Variant id" source="variant_id" />
            <TextField source="body_html" />
            <TextField source="barcode" />
            <TextField source="image_src" />
            <TextField source="image1" />
            <TextField source="image2" />
            <TextField source="image3" />
            <TextField source="option1" />
            <TextField source="option2" />
            <TextField source="option3" />
            <TextField source="option_value1" />
            <TextField source="option_value2" />
            <TextField source="option_value3" />
            <TextField source="created_at" />
            <TextField source="created_by" />
            <TextField source="updated_at" />
            <TextField source="updated_by" />
        </SimpleShowLayout>
    </Show>
);