import { ReactNode } from "react";
import { List, Datagrid, TextField } from 'react-admin';

import { Box } from '@mui/material';
import { EditButton } from "react-admin";


interface Props {
    children?: ReactNode
}

const PostListActionToolbar = ({ children, ...props }: Props) => (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
);

export const ProductsList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField label="product id" source="product_id" />
            <TextField label="タイトル" source="title" />
            <TextField label="在庫数" source="stock" />
            <TextField label="価格" source="price" />
            <TextField label="重さ" source="weight" />
            <TextField source="sku" />
            <TextField source="created_at" />
            <TextField source="created_by" />
            <TextField source="updated_at" />
            <TextField source="updated_by" />

            <PostListActionToolbar>
            <EditButton />
            </PostListActionToolbar>
            
        </Datagrid>
    </List>
);