import { AppBar, Layout, LayoutProps, UserMenu } from 'react-admin';

import React, { forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = forwardRef((_, ref:React.ForwardedRef<null>) => {
    const { logout } = useAuth0();

    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> Logout
        </MenuItem>
    );
});

const MyUserMenu = () => <UserMenu><LogoutButton /></UserMenu>;
const MyAppBar = () => <AppBar userMenu={<MyUserMenu />} />;
const CustomLayout = (props:LayoutProps) => <Layout {...props} appBar={MyAppBar}/>

export default CustomLayout;
