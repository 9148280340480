import { Create, SimpleForm, TextInput, Toolbar, SaveButton, useNotify, NumberInput} from 'react-admin';
import { useFormContext} from 'react-hook-form';
import { Box, Typography} from '@mui/material';


const PostCreateToolbar = () => {
    const notify = useNotify();
    const { reset } = useFormContext();

    return (
        <Toolbar>
            {/* <SaveButton label="save_and_edit" variant="text" /> */}
            <SaveButton
                label="save_and_add"
                type="button"
                variant="text"
                mutationOptions={{
                    onSuccess: () => {
                        reset();
                        window.scrollTo(0, 0);
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                    },
                }}
            />
        </Toolbar>
    );
};

export const PostCreate = () => (
    <Create>
    <SimpleForm toolbar={<PostCreateToolbar />} sx={{ maxWidth: 600 }}>

        <Typography variant="h6" gutterBottom>
            Products table:
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput label="Product ID" source="product_id" isRequired /> 
            </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="title" /> 
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="body_html" options={{ multiline: true }} />
            </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image1" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image2" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image3" />   
            </Box>
        </Box>

            
        <Typography variant="h6" gutterBottom>
            Products_options table:
        </Typography>
        
        <NumberInput label="Option ID" source="option_id" isRequired />
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option1" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option2" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option3" />   
            </Box>
        </Box>


        <Typography variant="h6" gutterBottom>
            Products_variants table:
        </Typography>

        <NumberInput label="Variant ID" source="variant_id" isRequired />

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option_value1" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option_value2" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="option_value3" />   
            </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="barcode" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="image_src" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="stock" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="price" />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="sku" />   
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="weight" />   
            </Box>
        </Box>

    </SimpleForm>
    </Create>
);
